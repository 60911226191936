import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
// mock
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey } from '../../auxiliares/funciones';

// ----------------------------------------------------------------------

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());

    const campos = ['id','codigo','accion','curso','estado','incidencia','estadoProceso','fechaProceso','activo'];
    const cabecera = ['ID','Código','Accion','Curso','Estado','Incidencia','Estado Proceso','Fecha Proceso','Estado'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
      if(!cargado){
        const body = {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}grupos`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0 && !isEmpty(result.data)) {
              setLista(result.data);
            }
            setKey(getUniqueKey());
            // eslint-disable-next-line react/prop-types
            props.showLoader(false);            
            setCargado(true);
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const validaBorrar = (obj) => obj.activo === 'Activo';
    const validaActivar = (obj) => obj.activo !== 'Activo';
    const validaMarcar = (obj) => obj.estadoproceso === 3;

    const MarcarR = (obj) => Marcar(0,obj);
    const MarcarD = (obj) => Marcar(4,obj);
    const Marcar = (estadoI,obj) =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      const body = {
        estado: estadoI,
        id: obj.id
      }
      const bodyJson = JSON.stringify(buildBody(usuario.token,body))
      fetch(`${CONFIG.api}grupo/marcar`,{
                method: 'POST',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          let msg ='Grupo marcado!';
          if (result.error === 0) {
            setLista([]);
            setCargado(false);
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
      })
    }

    const Activar = (obj) =>{
          // eslint-disable-next-line react/prop-types
          props.showLoader(true); 
          const body = {
            tabla: "grupos",
            activo: 1,
            id: obj.id
          }
          const bodyJson = JSON.stringify(buildBody(usuario.token,body))
          fetch(`${CONFIG.api}master/activo`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
              let msg ='Grupo activado!';
              if (result.error === 0) {   
                setLista([]);        
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
          })
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          tabla: "grupos",
          activo: 0,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Grupo desactivado!';
              if (result.error === 0) {    
                setLista([]);               
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:'error',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'warning',
        nombre:'Activar',
        icono:<i />,
        funcion: Activar,
        estilo: {},
        condition: validaActivar
      },
      {
        color:'primary',
        nombre:'Relanzar',
        icono:<i />,
        funcion: MarcarR,
        estilo: {},
        condition: validaMarcar
      },
      {
        color:'success',
        nombre:'Descartar',
        icono:<i />,
        funcion: MarcarD,
        estilo: {},
        condition: validaMarcar
      }
    ]

  return (    
    <Page title="Grupos" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                              </>}
                  title="Grupos"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
    </Page>
  );
}
